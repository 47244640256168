.footer-nav {

	@include mq {
		width: 100%;
	}
}

.footer-menu {
	background-color: #f0ebe4;
	color: $color_text-secondary;
	display: flex;
	font-family: $font__sub;
	justify-content: space-between;
	padding: 20px 0;

	&__con {
		align-items: center;
		display: flex;
		justify-content: space-between;

		@include mq() {
			align-items: flex-start;
			flex-direction: column;
		}
	}

	&__item {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;

		@include mq {
			flex-wrap: wrap;
			margin: 0 auto 32px;
			width: 100%;
		}

		a {
			color: $color_text-secondary;
			text-decoration: none;
			transition: all 0.2s ease;
			position: relative;
		}

		a:hover {
			color: rgba($color_text-secondary, 0.65);
			transition: all 0.2s ease;
		}

		a::after {
			height: 2px;
			background-color: $color_text-secondary;
			content: "";
			display: block;
			position: absolute;
			transition: all 0.2s ease;
			width: 0;
			bottom: -5px;
			left: 50%;
			transform: translateX(-50%);
		}

		a:hover::after {
			width: 100%;
		}
	}

	.menu-item {

		@include mq {
			margin-bottom: 16px;
			width: 50%;
		}

		&:not(:last-of-type) {
			margin-right: 64px;

			@include mq {
				margin-right: 0;
			}
		}
	}
}

.footer-social {

	@include mq {
		width: 100%;
	}

	a:hover {
		opacity: 0.75;
	}

	&__item,
	&__icons,
	.konbiniya,
	.bon-crepe {
		display: flex;
	}

	&__item,
	&__icons {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.konbiniya,
	.bon-crepe {

		@include mq {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			width: 50%;
		}
	}

	.konbiniya {
		margin-right: 32px;

		@include mq {
			margin-right: 0;
		}
	}

	&__item {
		display: flex;

		&:not(:last-child) {
			margin-right: 32px;
		}

		a {
			color: $color_text-secondary;
			text-decoration: none;
		}
	}

	&__title {
		align-items: center;
		display: flex;
		font-size: 14px;

		@include mq {
			margin-bottom: 8px;
		}
	}

	&__icons li {
		font-size: 20px;
		margin-left: 24px;

		@include mq {
			font-size: 24px;
			margin-left: 0;
			margin-right: 24px;
		}
	}
}

.site-info {
	font-family: $font__sub;
	font-weight: 300;
}
