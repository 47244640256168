h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
}

h1,
h2 {
	font-weight: 900;
}

h3 {
	font-weight: 600;
}
