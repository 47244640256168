* {
	scroll-behavior: smooth;

	&:focus {
		outline: none;
	}
}

body {
	background: $color__background-body; // Fallback for when there is no custom background color defined.
}

.mid-width {
	margin: 0 auto;
	max-width: 1440px;
	width: calc(100% - 128px);

	@include mq {
		width: calc(100% - 40px);
	}
}
