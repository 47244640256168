body,
button,
input,
select,
optgroup,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	font-size: 1rem;
	line-height: $font__line-height-body;
}

a[href="tel"] {
	color: inherit;
	text-decoration: none;
}

.type_main {
	font-family: $font__heading;
}

.type_sub {
	font-family: $font__sub;
}

.color_primary {
	color: $color_text-primary;
}

.color_secondary {
	color: $color_text-secondary;
}

.pen {

	&_orange {
		background: linear-gradient(transparent 60%, #fcab26 60%);
	}

	&_yellow {
		background: linear-gradient(transparent 60%, #f8df21 60%);
	}

	&_pink {
		background: linear-gradient(transparent 70%, #eea7be 70%);
	}
}

@import "headings";
@import "copy";
