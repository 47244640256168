.lazy-anm {
	opacity: 0;

	&.loaded {
		opacity: 1;

		&.fade {
			transition: all 0.3s ease-in-out;
			transition-delay: 0.15s;
		}
	}

	&.slideUp {
		transform: translateY(30%);
		transition: all 0.4s ease-in-out;

		&.loaded {
			transform: translateY(0);
		}
	}
}
