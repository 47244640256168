// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	width:
		map-get($columns, $numberColumns) -
		(($columns__margin * ($numberColumns - 1)) / $numberColumns);
}

@mixin mq($breakpoint: sm) {

	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}
