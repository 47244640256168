.no-sidebar {

	.site {
		display: grid;
		grid-template-areas:
			"header"
			"main"
			"footer";
		grid-template-columns: auto;
	}
}
