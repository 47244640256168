.error-404 {

	&__con {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		padding: 40px 0 80px;

		@include mq {
			padding: 20px 0 64px;
		}

		> *,
		.button.button_secondary {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__img svg {
		width: 100%;

		@include mq {
			width: 100%;
			height: 100%;
		}
	}

	&__heading {
		// font-size: 40px;
		font-weight: 600;
		letter-spacing: -0.5px;
		color: $color_text-primary;

		@include mq {
			font-size: 24px;
			line-height: 1.45;
			margin: 0 auto;
		}
	}

	&__subheading {
		color: $color_text-secondary;
	}
}
