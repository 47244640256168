.new-item {

	&__con {
		padding: 80px 0;

		@include mq {
			padding: 40px 0;
		}
	}

	&__heading {
		color: $color_text-primary;
		font-size: 32px;
		font-weight: 600;
		margin: 0 auto 40px;
		text-align: center;

		@include mq {
			font-size: 24px;
		}
	}

	&__slider {

		&.swiper-container {
			overflow: visible;
			width: 100%;

			@media (max-width: 768px) {
				padding: 0;
			}

			.swiper-slide {

				@include mq {

				}
			}

			.swiper-slide-next,
			.swiper-slide-prev {
				opacity: 0.4;
				transform: scale(0.75);
			}

			.swiper-slide:not(.swiper-slide-active, .swiper-slide-next, .swiper-slide-prev) {
				opacity: 0.2;
				transform: scale(0.6);
			}
		}
	}

	&__slider-prev,
	&__slider-next {
		align-items: center;
		display: flex;
		height: 32px;
		justify-content: center;
		margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
		position: absolute;
		top: 50%;
		width: 32px;
		z-index: 2;
		transition: opacity 0.15s ease-out;

		&:hover {
			opacity: 0.65;
			transition: opacity 0.15s ease-out;
		}

		img {
			cursor: pointer;
			height: 100%;
			width: 100%;
		}
	}

	&__slider-prev {
		left: 64px;

		@include mq {
			left: 8px;
		}
	}

	&__slider-next {
		right: 64px;

		@include mq {
			right: 8px;
		}
	}

	&__item-con {
		align-items: center;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__badge {
		align-items: center;
		background-color: $color-red;
		border-radius: 50%;
		color: $color_white;
		display: flex;
		font-weight: 600;
		height: 92px;
		justify-content: center;
		line-height: 1.25;
		padding-top: 8px;
		position: absolute;
		right: -15px;
		text-align: center;
		top: -25px;
		width: 100px;

		@include mq {
			font-size: 14px;
			height: 72px;
			right: 32px;
			width: 80px;
		}
	}

	&__item-img {
		height: auto;
		margin-bottom: 20px;
		max-height: 200px;
		max-width: 200px;
	}

	&__content-con {
		display: flex;
		flex-direction: column;

		@include mq {
			max-width: 80%;
		}
	}

	&__item-name,
	&__item-name-jp,
	&__item-price {
		color: $color_text-primary;
	}

	&__item-name {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.25;
		margin: 0 auto 8px;

		@include mq {
			font-size: 18px;
		}
	}

	&__item-name-jp {
		font-family: $font__sub;
		font-size: 16px;
		font-weight: 600;
		margin: 0 auto 12px 0;

		@include mq {
			font-size: 14px;
		}
	}

	&__item-desc > * {
		color: $color_text-secondary;
		font-family: $font__sub;
		font-size: 16px;
		margin: 0 auto 8px;

		@include mq {
			font-size: 14px;
		}
	}

	&__item-price {
		font-size: 24px;
		font-weight: 600;
		margin: 0;
		text-align: right;
		width: 100%;

		@include mq {
			font-size: 20px;
		}

		&_strikethrough,
		&_arrow {
			color: $color_text-secondary;
			font-size: 20px;

			@include mq {
				font-size: 18px;
			}
		}

		&_strikethrough {
			text-decoration: line-through;
		}

		&_discount {
			color: $color-red;
		}
	}
}
