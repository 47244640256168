button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	background: $color__background-button;
	border: 1px solid;
	border-color: $color__border-button;
	border-radius: 3px;
	color: rgba(0, 0, 0, 0.8);
	line-height: 1;
	padding: 0.6em 1em 0.4em;

	&:hover {
		border-color: $color__border-button-hover;
	}

	&:active,
	&:focus {
		border-color: $color__border-button-focus;
	}
}

.button {
	display: flex;
	height: max-content;
	justify-content: center;
	text-decoration: none;
}

.button_primary,
.button_primary:visited {
	background: $color_red;
	border: 1px solid $color_red;
	border-bottom: 2px solid #a6161a;
	border-radius: 5px;
	color: $color_white;
	font-family: $font__heading;
	font-weight: 600;
	overflow: hidden;
	padding: 12px;
	position: relative;
	width: 190px;

	&::before,
	&::after {
		content: "";
		position: absolute;
		transition: all 0.25s ease;
		border-radius: 50%;
		width: 150%;
		height: 500%;
		top: -200%;
		bottom: -200%;
		left: -25%;
		right: -25%;
		opacity: 0;
		display: block;
		transform: scale(0, 0);
	}

	&::before {
		background: #eb4247;
		transition: all 0.25s ease;
	}

	// &::after {
	// 	background: $color_red;
	// 	transition: all 0.3s ease;
	// }

	&:hover {
		background: $color_red;

		&::before,
		&::after {
			animation: ripple;
			animation-iteration-count: 1;
			animation-duration: 0.7s;
			opacity: 1;
		}

		&::before {
			z-index: 0;
			animation-fill-mode: forwards;
		}

		// &::after {
		// 	animation-delay: 0.15s;
		// 	z-index: 1;
		// }
	}

	&:active {
		border-bottom: 1px solid #a6161a;
		transform: translateY(1px);
		transition: all 0.25s ease-out;
		opacity: 0.75;
	}

	span {
		color: $color_white;
		z-index: 2;
		position: relative;
	}
}

.button_secondary,
.button_secondary:visited {
	background: $color_white;
	border: 1px solid $color_red;
	border-radius: 5px;
	color: $color_red;
	font-family: $font__heading;
	font-weight: 600;
	overflow: hidden;
	padding: 12px;
	position: relative;
	width: 190px;
	transition: all 0.25s ease;

	&::before,
	&::after {
		content: "";
		position: absolute;
		transition: all 0.25s ease;
		border-radius: 50%;
		width: 150%;
		height: 500%;
		top: -200%;
		bottom: -200%;
		left: -25%;
		right: -25%;
		opacity: 0;
		display: block;
		transform: scale(0, 0);
	}

	&::before {
		background: #f7f7f7;
		transition: all 0.25s ease;
	}

	// &::after {
	// 	background: $color_white;
	// 	transition: all 0.3s ease;
	// }

	&:hover {
		background: $color_white;
		transition: all 0.25s ease;

		&::before,
		&::after {
			animation: ripple;
			animation-iteration-count: 1;
			animation-duration: 0.7s;
			opacity: 1;
		}

		&::before {
			z-index: 0;
			animation-fill-mode: forwards;
		}

		// &::after {
		// 	animation-delay: 0.15s;
		// 	z-index: 1;
		// }
	}

	&:active {
		transition: all 0.25s ease-out;
		opacity: 0.75;
	}

	&:active::before {
		background: $color_white;
		transition: all 0.25s ease;
		opacity: 0.75;
	}

	span {
		color: $color-red;
		z-index: 2;
		position: relative;
	}
}

@keyframes ripple {

	from {
		transform: scale(0, 0);
	}

	to {
		transform: scale(1, 1);
	}
}
