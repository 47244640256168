.cta {
	position: relative;

	&__background {
		bottom: 0;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__con {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 80px 0;
		position: relative;
		z-index: 0;

		@include mq {
			padding: 64px 0;
		}
	}

	&__heading,
	&__subheading,
	&__desc {
		color: $color_text-primary;
		text-align: center;
	}

	&__heading {
		font-size: 32px;
		font-weight: 900;
		margin: 0 auto 16px;

		@include mq {
			font-size: 24px;
			line-height: 1.25;
		}

		br {
			display: none;

			@include mq {
				display: block;
			}
		}
	}

	&__subheading {
		font-size: 24px;
		margin: 0 auto 24px;

		@include mq {
			font-size: 18px;
		}
	}

	&__desc {
		font-size: 18px;
		text-align: center;

		@include mq {
			font-size: 16px;
		}

		p {
			margin: 8px auto;
		}
	}

	&__button {
		align-items: center;
		display: flex;
		margin-bottom: 16px;

		@include mq {
			flex-direction: column;
		}

		&:active {
			margin-bottom: 17px;
		}
	}

	&_konbiniya {
		background-color: #fff2cc;
	}

	&_boncrepe {
		background-color: #ffeff4;

		.button {
			width: 280px;

			img {
				transition: all 0.25s ease;
			}

			&:hover img {
				opacity: 0.75;
				transition: all 0.25s ease;
			}

			&.button_ubereats,
			&.button_doordash {
				background: #fde9ef;
			}

			&.button_ubereats {
				margin-right: 32px;

				@include mq {
					margin-bottom: 32px;
					margin-right: 0;
				}
			}

			&.button_doordash {
				margin-left: 32px;

				@include mq {
					margin-left: 0;
				}
			}
		}
	}
}
