// stylelint-disable value-keyword-case
$font__main: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
	Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
// stylelint-enable value-keyword-case
$font__code: monaco, consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;

// new style
$font__heading: "Gotham", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font__sub: "Noto Sans JP", sans-serif;
