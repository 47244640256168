.about-konbiniya {

	a:hover {
		opacity: 0.75;
	}

	&__main-con {
		display: flex;
		flex-direction: row;
		margin-bottom: 64px;
		width: 100%;

		@include mq {
			flex-direction: column;
			margin-bottom: 24px;
		}
	}

	&__content-con,
	&__img {
		width: 50%;

		@include mq {
			width: 100%;
		}
	}

	&__content-con {
		padding-right: 40px;
		width: 100%;

		@include mq {
			padding: 0;
		}
	}

	&__img {
		padding-left: 16px;
		width: 100%;

		@include mq {
			display: none;
		}

		&_mb {
			display: none;

			@include mq {
				display: block;
				margin: 24px auto 16px;
			}
		}
	}

	svg {
		width: 100% !important;

		@include mq {
			height: auto;
		}
	}

	&__heading,
	&__heading_jp,
	&__subheading {
		color: $color_text-primary;
	}

	&__heading {
		font-size: 40px;
		font-weight: 600;
		letter-spacing: -0.5px;
		margin: 0 auto 8px;

		@include mq {
			font-size: 24px;
			line-height: 1.5;
			margin: 0 auto;
		}

		&_jp {
			font-family: $font__sub;
			font-size: 24px;
			margin: 0 auto 24px;

			@include mq {
				font-size: 16px;
				margin: 0;
			}
		}
	}

	&__desc {
		color: $color_text-secondary;
		font-family: $font__sub;
		font-size: 16px;
		line-height: 1.65;
		margin: 0 auto 16px;

		@include mq {
			font-size: 16px;
		}
	}

	&__subheading {
		font-size: 24px;

		@include mq {
			font-size: 18px;
		}
	}

	&__slider {
		margin: 0 auto;
		position: relative;
		z-index: 0;

		.swiper-container {
			height: 100%;
			margin: 0 40px;

			@include mq {
				margin: 0 24px;
			}
		}
	}

	&__slider-button_prev,
	&__slider-button_next {
		align-items: center;
		bottom: 0;
		cursor: pointer;
		display: flex;
		height: 100%;
		justify-content: center;
		outline: none;
		position: absolute;
		top: 0;
		width: 40px;
		transition: opacity 0.15s ease-out;

		&:hover {
			opacity: 0.65;
			transition: opacity 0.15s ease-out;
		}

		@include mq {
			width: 24px;
		}
	}

	&__slider-button_prev {
		left: 0;
	}

	&__slider-button_next {
		right: 0;
	}
}

// svg
.arm-right {
	transform-origin: 100%;
	animation: wave 2s ease-in-out 0s infinite alternate;
}

@keyframes wave {

	0% {
		transform: translate(0, 0) rotate(0deg);
	}

	50% {
		transform: translate(0.75%, -7%) rotate(-10deg);
	}

	100% {
		transform: translate(0, 0) rotate(0deg);
	}
}
