.top {
	display: flex;
	padding: 0 16px;
	opacity: 0;

	@include mq {
		flex-direction: column;
		padding: 0;
	}

	a:hover {
		opacity: 0.75;
	}

	&.loaded {
		opacity: 1;
		transition: opacity 0.35s ease-in-out;
	}

	&__slider {
		height: 31.25vw;
		align-items: center;
		display: flex;
		justify-content: center;
		width: 75%;

		@include mq {
			height: 41.406vw;
			margin-bottom: 8px;
			width: 100%;
		}

		.swiper-slide-active,
		.swiper-slide-duplicate-active {
			opacity: 1 !important;
		}

		.swiper-pagination-bullet {
			background: $color_text-secondary;
			border-radius: 0;
			height: 12px;
			margin: 0 12px !important;
			opacity: 0.25;
			width: 12px;
		}

		.swiper-pagination-bullet-active {
			background: $color_text-secondary;
			border-radius: 0;
			height: 12px;
			margin: 0 12px !important;
			opacity: 1;
			width: 12px;
		}

		img {
			height: 31.25vw;
			object-fit: cover;

			@include mq {
				height: unset;
			}
		}
	}

	.aside {
		display: flex;
		flex-direction: column;
		padding: 0 0 0 16px;
		width: 25%;
		height: 31.25vw;

		@include mq {
			height: auto;
			padding: 0;
			width: 100%;
		}

		&__discount,
		&__boncrepe,
		&__travelbox {
			transition: opacity 0.3s ease;

			img {
				display: block;
				height: 100%;
				width: 100%;
			}
		}

		&__discount {
			height: 17.891vw;
			margin-bottom: 16px;
			width: 100%;

			@include mq {
				margin-bottom: 8px;
				height: auto;
				overflow: hidden;
			}
		}

		&__con {
			display: flex;
			flex-direction: column;

			@include mq {
				flex-direction: row-reverse;
			}
		}

		&__boncrepe,
		&__travelbox {
			height: auto;
			width: 100%;

			@include mq {
				height: 100%;
			}
		}

		&__boncrepe {
			margin-bottom: 16px;

			@include mq {
				margin-bottom: 8px;
			}
		}
	}
}
