.page-header {
	align-items: center;
	display: flex;
	height: 320px;
	overflow: hidden;
	position: relative;
	width: 100%;

	&__background {
		width: 100%;
		height: 100%;

		@include mq {
			height: 100%;
			position: absolute;
			width: auto;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__overlay {
		background: rgba(0, 0, 0, 0.4);
		display: block;
		height: 100%;
		position: absolute;
		width: 100%;
	}

	&__content-con {
		align-items: center;
		bottom: 0;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;
		padding: 32px 20px;

		@include mq {
			padding: 32px 20px;
		}
	}

	&__heading,
	&__subheading {
		color: $color_text-primary;
	}

	&__heading {

		@include mq {
			font-size: 24px;
			margin: 0 auto 8px;
		}

		&_img {
			margin-bottom: 24px;

			@include mq {
				width: 120px;
				margin-bottom: 16px;
			}
		}
	}

	&__subheading {
		margin: 0;

		@include mq {
			font-size: 16px;
		}
	}
}
