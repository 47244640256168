.footer {
	// temp
	&__heading,
	&__subheading,
	&__address,
	&__phone,
	&__email,
	&__hours,
	&__note {
		color: $color_text-primary;
	}

	&__heading {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 0;
		margin-top: 0;

		@include mq {
			font-size: 24px;
			line-height: 1.25;
			margin-bottom: 8px;
		}
	}

	&__subheading {
		font-size: 18px;
		margin-bottom: 16px;
		margin-top: 0;

		@include mq {
			font-size: 16px;
		}
	}

	&__address {
		font-weight: 600;
		margin-top: 1.5em;
	}

	&__hours,
	&__phone,
	&__email {
		font-size: 16px;
	}

	&__hours,
	&__email {
		margin-bottom: 16px;
	}

	&__phone {
		margin-bottom: 32px;
	}

	&__note {

		@include mq {
			margin-bottom: 32px;
		}
	}

	&__menu ul li a {
		color: $color_text-secondary;
		text-decoration: none;
	}
}

.footer-info {
	background-color: #faf8f5;
	padding: 32px 0;

	&__con {
		display: flex;
		justify-content: space-between;

		@include mq(md) {
			align-items: flex-start;
			flex-direction: column;
		}
	}

	&__social-container {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__social-link,
	&__social-link:visited {
		color: $color_text-primary;
		font-size: 24px;
		text-decoration: none;
	}

	.google-maps {
		height: 300px;
		width: 100%;

		&__iframe {
			height: 100%;
			width: 100%;
		}
	}

	.mapouter {
		position: relative;
		text-align: right;
	}

	.gmap_canvas {
		background: none !important;
		height: 310px;
		overflow: hidden;
		width: calc(50vw - 80px);

		@include mq(md) {
			width: calc(100vw - 128px);
		}

		@include mq {
			width: calc(100vw - 40px);
		}
	}
}

.site {

	&-info {
		background-color: $color_text-secondary;
		color: #fff;
		margin: 0 auto;
		padding: 16px 0;
		text-align: center;
	}
}
