.site {
	display: grid;
	grid-template-areas:
		"header header"
		"main sidebar"
		"footer footer";
	grid-template-columns: auto ($size__site-sidebar);
}

.site-header {
	grid-area: header;
}

.site-main {
	grid-area: main;
	overflow: hidden; /* Resolves issue with <pre> elements forcing full width. */
}

.widget-area {
	grid-area: sidebar;
}

.site-footer {
	grid-area: footer;
}

@import "no-sidebar";
