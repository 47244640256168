.group-link {

	&__con {
		padding: 80px 0;

		@include mq {
			padding: 64px 0;
		}
	}

	&__main-con {
		display: flex;

		&_boncrepe {
			flex-direction: row;
			margin-bottom: 64px;

			@include mq {
				flex-direction: column;
				align-items: center;
				margin-bottom: 40px;
			}
		}

		&_travelbox {
			flex-direction: row-reverse;

			@include mq {
				flex-direction: column;
				align-items: center;
			}
		}
	}

	&__content-con,
	&__image {
		width: 50%;

		@include mq {
			max-width: 640px;
			width: 100%;
		}
	}

	&__image {
		align-items: flex-start;
		display: flex;
		justify-content: center;

		@include mq {
			display: none;
		}

		picture {
			width: 100%;
		}

		picture .lazy-img {
			object-fit: cover;
			width: 100%;
		}
	}

	&__image-mb {
		display: none;

		@include mq {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 100%;
		}
	}

	&__content-con_boncrepe,
	&__image_travelbox {
		padding: 0 20px 0 0;

		@include mq {
			padding: 0;
		}
	}

	&__content-con_travelbox,
	&__image_boncrepe {
		padding: 0 0 0 20px;

		@include mq {
			padding: 0;
		}
	}

	&__heading {
		color: $color_text-primary;
		font-size: 32px;
		margin: 0 auto 16px;

		@include mq {
			font-size: 24px;
			line-height: 1;
		}
	}

	&__desc {
		color: $color_text-secondary;
	}

	&__button {

		@include mq {
			margin: 0 auto;
		}
	}
}
