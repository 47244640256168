.boncrepe-menu {
	margin-bottom: 80px;
	margin-top: 80px;

	@include mq {
		margin-top: 0;
		padding-top: 40px;
	}

	&__heading {
		color: $color_text-primary;
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 8px;
		text-align: center;

		@include mq {
			font-size: 24px;
			margin-bottom: 32px;
		}
	}

	&__category-con-wrap {
		border-bottom: 1px solid $color-border;
		transition: 0.3s top;

		@include mq(md) {
			border-bottom: none;
		}

		&_active {
			background-color: $color_white;
			position: fixed;
			top: 0;
			transition: 0.3s top;
			width: 100%;
		}
	}

	&__category-con {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		list-style: none;
		margin: 0 auto;
		padding: 16px 0;

		@include mq(md) {
			flex-wrap: wrap;
			padding: 0;
		}

		* {
			text-decoration: none;
			user-select: none;
		}

		&_active {

			// scrollbar Firefox
			scrollbar-width: thin;
			scrollbar-color: rgba($color_text-secondary, 0.5) $color_white;

			@include mq(md) {
				// this is high priority although flex-wrap: wrap is declared above
				flex-wrap: nowrap !important;
				margin: 0;
				width: 100%;
				overflow-x: auto;
			}

			&::-webkit-scrollbar {
				height: 4px;
				background: transparent;
			}

			&::-webkit-scrollbar-track {
				background: $color_white;
			}

			&::-webkit-scrollbar-thumb {
				background-color: rgba($color_text-secondary, 0.5);
			}

			.boncrepe-menu__category-header-con,
			.boncrepe-menu__category-header-con:not(:first-child) {

				@include mq(md) {
					width: max-content;
					flex-shrink: 0;
					padding: 8px 20px;
				}
			}
		}
	}

	&__category-header-con {
		padding: 0 8px;

		@include mq(md) {
			border-bottom: 1px solid $color-border;
			padding: 8px;
			width: 100%;
		}

		&:not(:first-child) {

			@include mq(md) {
				width: 50%;
			}
		}
	}

	&__category-header {
		color: $color_text-primary;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
	}

	&__category-header-jp {
		color: $color_text-secondary;
		font-size: 12px;
		font-weight: 600;
		text-align: center;
	}

	&__category-wrap {
		padding-top: 80px;
	}

	&__category-heading-con {
		margin-bottom: 32px;
	}

	&__category-heading {
		color: $color_text-primary;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.3;
		text-align: center;
		width: max-content;
		margin: auto;
	}

	&__category-heading-jp {
		color: $color_text-secondary;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
	}

	&__category-item-wrap,
	&__category-item-wrap-recommended {
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		padding: 0;
		max-width: 850px;
		width: 100%;
	}

	&__category-item-wrap {
		justify-content: space-between;

		@media (max-width: 600px) {
			justify-content: center;
		}
	}

	&__category-item-wrap-recommended {
		justify-content: flex-start;
	}

	&__item-con {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
		max-width: 416px;
		width: 50%;

		@media (max-width: 600px) {
			width: 100%;
		}

		&:nth-child(odd) {
			padding-right: 16px;

			@media (max-width: 600px) {
				padding-right: 0;
			}
		}

		&:nth-child(even) {
			padding-left: 16px;

			@media (max-width: 600px) {
				padding-left: 0;
			}
		}

		&.boncrepe-menu_recommended {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin-bottom: 16px;
			// max-width: 400px;
			width: 33.33%;

			@media (max-width: 600px) {
				max-width: 50%;
				width: 50%;
			}

			&:nth-child(odd) {
				padding-right: 0;

				@media (max-width: 600px) {
					padding-right: 8px;
				}
			}

			&:nth-child(even) {
				padding-left: 0;

				@media (max-width: 600px) {
					padding-left: 8px;
				}
			}

			.boncrepe-menu__item-name-con {
				align-items: center;
				display: flex;
				flex-direction: column;
			}
		}
	}

	&__item-img {
		align-items: center;
		display: flex;
		height: 160px;
		justify-content: center;
		margin-bottom: 8px;
		overflow: hidden;
		width: 160px;

		@include mq {
			width: 120px;
			height: 120px;
		}

		img {
			height: auto;
			object-fit: contain;
			width: 100%;
		}
	}

	&__item-name {
		color: $color_text-primary;
		font-size: 16px;
		font-weight: 600;
	}

	&__item-name-jp {
		color: $color_text-secondary;
		font-size: 12px;
		font-weight: 400;
	}

	&__item-price {
		color: $color_text-primary;
		font-size: 16px;
		margin-left: 8px;
	}
}
