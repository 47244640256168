a {
	color: $color__link;
	transition: all 0.2s ease;

	&:visited {
		color: $color__link-visited;
	}

	&:hover,
	&:focus,
	&:active {
		color: $color__link-hover;
		transition: all 0.2s ease;
	}

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}
}

.underline {
	transition: all 0.2s ease;
	position: relative;

	&::after {
		height: 2px;
		background-color: $color_text-secondary;
		content: "";
		display: block;
		position: absolute;
		transition: all 0.2s ease;
		width: 0;
		bottom: -5px;
		left: 50%;
		transform: translateX(-50%);
	}

	&:hover::after {
		width: 100%;
	}
}
