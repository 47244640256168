.modal {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 1;
	position: fixed;
	top: 0;
	transition: opacity 0.3s ease, z-index 0.3s ease;
	width: 100%;
	z-index: 15;

	&_disabled {
		opacity: 0;
		z-index: -10;
	}

	&__overlay {
		background: rgba(#000, 0.6);
		cursor: pointer;
		height: 100%;
		position: fixed;
		width: 100%;
	}

	&__con {
		align-items: center;
		background: $color_white;
		display: flex;
		flex-direction: column;
		height: 600px;
		justify-content: center;
		margin: auto;
		position: relative;
		top: 0;
		width: 600px;

		@include mq {
			// height: max-content;
			max-height: calc(100vh - 96px);
			width: calc(100% - 40px);
		}
	}

	&__header-con {
		width: 100%;
		height: 70px;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__heading,
	&__heading-jp {
		font-weight: 600;
		text-align: center;
	}

	&__heading {
		color: $color_text-primary;
		font-size: 24px;
		margin-top: 8px;

		@include mq {
			font-size: 18px;
		}
	}

	&__heading-jp {
		color: $color_text-secondary;
		font-size: 16px;

		@include mq {
			font-size: 14px;
		}
	}

	&__content-con {
		position: absolute;
		top: 70px;
		left: 0;
		height: calc(100% - 70px);
		width: 100%;
		overflow: auto;
	}

	&__content {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 24px;
		width: 100%;
		height: 100%;
		overflow: auto;

		@include mq {
			padding: 20px;
		}

		@media (max-width: 600px) {
			padding: 16px;
		}

		*:not(ul, ol) {
			text-align: center;
		}

		ul,
		ol {
			margin-left: 0;

			@media (max-width: 600px) {
				padding-left: 24px;
			}
		}

		a {
			display: flex;
			height: max-content;
			justify-content: center;
			font-family: $font__heading;
			font-weight: 600;
			padding: 12px;
			min-width: 190px;
			color: $color_red;
			border: 1px solid $color_red;
			border-radius: 5px;
			text-decoration: none;
			transition: all 0.25s ease;

			&:hover {
				opacity: 0.75;
				transition: all 0.25s ease;
			}
		}

		p {
			margin: 0 0 16px;
			line-height: 1.5;
		}

		.alignleft {
			margin-right: auto;
			margin-left: auto;
		}
	}

	&__close {
		cursor: pointer;
		height: 40px;
		position: absolute;
		right: -40px;
		top: 0;
		width: 40px;

		@include mq {
			position: absolute;
			right: 0;
			top: -40px;
		}

		svg {
			fill: $color_white;
			height: 100%;
			width: 100%;
		}
	}
}
