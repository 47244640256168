.social-widget {
	background: $color_white;

	a:hover {
		opacity: 0.75;
	}

	&__con {
		padding: 64px 0;
	}

	&__header-con {
		align-items: flex-end;
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;

		@include mq() {
			align-items: flex-start;
			flex-direction: column;
		}
	}

	&__heading {
		color: $color_text-primary;
		font-size: 24px;
		font-weight: 600;

		@include mq {
			font-size: 18px;
		}
	}

	&__subheading {
		color: $color_text-primary;
		font-size: 14px;
		font-weight: 600;
	}

	&__icons {
		align-items: center;
		color: $color_text-primary;
		display: flex;
		font-size: 24px;
		justify-content: flex-end;
		list-style: none;
		margin: 0;
		padding: 0;

		@include mq {
			margin-top: 20px;
		}

		li:not(:last-of-type) {
			margin-right: 24px;
		}

		li a,
		li a:visited {
			color: $color_text-primary;
		}
	}

	&__widget-con {
		display: flex;
		justify-content: space-around;
		width: 100%;

		@include mq() {
			align-items: center;
			flex-direction: column;
		}
	}

	&_fb {
		border: 1px solid $color-border;
		height: 540px;
		width: 500px;

		@include mq() {
			margin-bottom: 20px;
			max-width: 500px;
			width: 100%;
		}
	}

	&_tw {
		border: 1px solid $color-border;
		height: 540px;
		overflow: auto;
		width: 400px;

		@include mq() {
			max-width: 500px;
			width: 100%;
		}
	}
}

