.header {
	background: $color_white;
	border-bottom: 1px solid $color-border;
	height: 80px;
	left: 0;
	position: fixed;
	top: 0;
	transition: top 0.3s;
	width: 100%;
	z-index: 10;

	&__con {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 16px 40px;
		height: 100%;

		@include mq(lg) {
			padding: 16px 16px;
		}
	}

	&__menu {

		a img {
			transition: all 0.25s ease;

			&:hover {
				opacity: 0.75;
				transition: all 0.25s ease;
			}
		}
	}

	&__title {
		margin: 0;
		width: 100%;

		@include mq {
			margin: 0 8px 0 0;
		}
	}

	&__img {
		align-items: center;
		display: flex;
		height: auto;
		width: 180px;
	}

	&__menu-item {

		li {
			margin-left: 32px;
			width: max-content;
		}

		li a {
			color: $color_text-primary;
		}

		a:hover {
			color: rgba($color_text-primary, 0.65);
			transition: all 0.3s ease;
		}

		a::after {
			height: 2px;
			background-color: $color_text-primary;
			content: "";
			display: block;
			position: absolute;
			transition: all 0.3s ease;
			width: 0;
			bottom: -5px;
			left: 50%;
			transform: translateX(-50%);
		}

		a:hover::after {
			width: 100%;
		}
	}

	&__cta {
		display: flex;
	}

	&__cta-button {

		&_konbiniya {

			@include mq {
				width: 140px;
			}
		}

		&_bon-crepe {

			@include mq {
				display: none !important;
			}
		}
	}

	&__desc {
		align-items: center;
		color: $color_text-primary;
		display: flex;
		margin: 0 16px 0 0;
		text-align: right;

		@include mq(md) {
			display: none;
		}
	}

	&__mobile-wrap {
		display: none;
		height: 0;
		width: 0;

		@include mq {
			display: block;
		}
	}

	&__mobile-wrap-active {
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
	}

	&__mobile-overlay {
		background-color: rgba($color_text-primary, 0.5);
		display: none;
		height: 100%;
		position: relative;
		width: 100%;
	}

	&__mobile-overlay-active {
		display: block;
	}

	&__toggle {
		cursor: pointer;
		display: none;

		@include mq {
			align-items: center;
			display: flex;
			height: 100%;
		}
	}

	&__close {
		cursor: pointer;
		display: none;
		height: 40px;
		margin: 16px 0 0;
		position: absolute;
		right: 75%;
		top: 0;
		width: 40px;

		svg {
			fill: $color_white;
			height: 100%;
			width: 100%;
		}
	}

	&__close-active {
		display: block;
	}

	&__menu,
	&__cta {
		display: flex;
	}

	&__menu {
		justify-content: space-between;

		.main-navigation {
			align-items: center;
			display: flex;

			@include mq {
				display: none;
			}
		}
	}

	&__label {
		align-items: center;
		color: $color_text-primary;
		cursor: pointer;
		display: flex;
		margin-right: 8px;
		text-transform: uppercase;
	}

	&__hamburger {
		height: 40px;
		position: relative;
		width: 40px;

		svg {
			display: block;
			fill: $color_text-primary;
			height: 100%;
			position: relative;
			width: 100%;
			z-index: -1;
		}
	}

	&__mobile-menu-wrap {
		background-color: $color_white;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		position: fixed;
		right: 0;
		top: 0;
		transform: translateX(100%);
		transition: transform 0.2s ease;
		width: 75%;
	}

	&__mobile-menu-wrap-active {
		transform: translateX(0);
		transition: transform 0.2s ease;
	}

	&__mobile-menu-section {

		&:first-of-type {
			border-bottom: 1px solid $color-border;
		}

		&:last-of-type {
			border-top: 1px solid $color-border;
		}
	}

	&__mobile-menu {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-around;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: flex;
		}

		li a {
			color: $color-text-primary;
			height: 100%;
			padding: 16px 32px;
			text-decoration: none;
			width: 100%;
		}
	}

	&__mobile-menu-copy {
		margin: 24px auto;
		font-size: 14px;
		text-align: center;
	}

	&__mobile-cta-button {
		margin: 0 auto 24px;
	}
}

