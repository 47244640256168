/* Navigation
--------------------------------------------- */
@import "navigation/navigation";
@import "navigation/header";
@import "navigation/footer-info";
@import "navigation/footer-menu";

/* Posts and pages
--------------------------------------------- */
@import "content/posts-and-pages";
@import "content/about-konbiniya";
@import "content/cta";
@import "content/group-link";
@import "content/new-item";
@import "content/top";
@import "content/boncrepe-menu";
@import "content/page-header";
@import "content/modal";
@import "content/main";
@import "content/404";

/* Comments
--------------------------------------------- */
@import "comments/comments";

/* Widgets
--------------------------------------------- */
@import "widgets/widgets";
@import "widgets/social-widget";

/* Media
--------------------------------------------- */
@import "media/media";

/* Captions
--------------------------------------------- */
@import "media/captions";

/* Galleries
--------------------------------------------- */
@import "media/galleries";
