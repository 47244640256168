.sticky {
	display: block;
}

.post,
.page {
	margin: 0;
}

.updated:not(.published) {
	display: none;
}

.page-content,
.entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}

.page-links {
	clear: both;
	margin: 0 0 1.5em;
}

.site-main {
	margin-top: 80px;
}

.post {

	&__breadcrumb,
	&__breadcrumb a {
		color: $color_text-secondary;
	}

	&__con {
		padding: 40px 0 80px;
		max-width: 680px;

		@include mq {
			width: 100%;
			padding: 20px 20px 40px;
		}
	}

	&__item-img {
		display: flex;
		align-items: center;
		width: 480px;
		height: 480px;
		object-fit: contain;
		margin: auto;

		@include mq {
			width: calc(100% - 40px);
			height: auto;
			max-width: 480px;
			max-height: 480px;
		}
	}

	&__content {
		color: $color_text-primary;
		margin-bottom: 40px;

		@include mq {
			margin-bottom: 20px;
		}
	}

	&__item-price {
		font-size: 24px;
		font-weight: 600;
		margin: 0;
		// text-align: right;
		width: 100%;

		@include mq {
			font-size: 20px;
		}

		&_strikethrough,
		&_arrow {
			color: $color_text-secondary;
			font-size: 20px;

			@include mq {
				font-size: 18px;
			}
		}

		&_strikethrough {
			text-decoration: line-through;
		}

		&_discount {
			color: $color-red;
		}
	}

	&__item-price-label {
		font-size: 16px;
	}
}

.posted-on {
	color: $color_text-secondary;

	a {
		text-decoration: none;
		pointer-events: none;

		&:hover {
			pointer-events: none;
		}
	}
}

.posted-on a,
.nav-links .nav-previous a,
.nav-links .nav-next a {
	color: $color_text-secondary;
	transition: opacity 0.3s ease;

	&:hover {
		opacity: 0.75;
		transition: opacity 0.3s ease;
	}
}
